// import { AngularEditorConfig } from '@kolkov/angular-editor';

export const HelperData = {
  getPhone() {
    var phoneList = [
      {
        name: 'Aruba',
        code: 'AW',
        callingCode: '+297',
        flag_32: 'AW-32.png',
      },
      {
        name: 'Afghanistan',
        code: 'AF',
        callingCode: '+93',
        flag_32: 'AF-32.png',
      },
      {
        name: 'Angola',
        code: 'AO',
        callingCode: '+244',
        flag_32: 'AO-32.png',
      },
      {
        name: 'Anguilla',
        code: 'AI',
        callingCode: '+1264',
        flag_32: 'AI-32.png',
      },
      {
        name: 'Ãland Islands',
        code: 'AX',
        callingCode: '+358',
        flag_32: 'AX-32.png',
      },
      {
        name: 'Albania',
        code: 'AL',
        callingCode: '+355',
        flag_32: 'AL-32.png',
      },
      {
        name: 'Andorra',
        code: 'AD',
        callingCode: '+376',
        flag_32: 'AD-32.png',
      },
      {
        name: 'United Arab Emirates',
        code: 'AE',
        callingCode: '+971',
      },
      {
        name: 'Argentina',
        code: 'AR',
        callingCode: '+54',
      },
      {
        name: 'Armenia',
        code: 'AM',
        callingCode: '+374',
        flag_32: 'AM-32.png',
      },
      {
        name: 'American Samoa',
        code: 'AS',
        callingCode: '+1684',
        flag_32: 'AS-32.png',
      },
      {
        name: 'Antigua and Barbuda',
        code: 'AG',
        callingCode: '+1268',
        flag_32: 'AG-32.png',
      },
      {
        name: 'Australia',
        code: 'AU',
        callingCode: '+61',
        flag_32: 'AU-32.png',
      },
      {
        name: 'Austria',
        code: 'AT',
        callingCode: '+43',
        flag_32: 'AT-32.png',
      },
      {
        name: 'Azerbaijan',
        code: 'AZ',
        callingCode: '+994',
        flag_32: 'AZ-32.png',
      },
      {
        name: 'Burundi',
        code: 'BI',
        callingCode: '+257',
        flag_32: 'BI-32.png',
      },
      {
        name: 'Belgium',
        code: 'BE',
        callingCode: '+32',
        flag_32: 'BE-32.png',
      },
      {
        name: 'Benin',
        code: 'BJ',
        callingCode: '+229',
        flag_32: 'BJ-32.png',
      },
      {
        name: 'Burkina Faso',
        code: 'BF',
        callingCode: '+226',
        flag_32: 'BF-32.png',
      },
      {
        name: 'Bangladesh',
        code: 'BD',
        callingCode: '+880',
        flag_32: 'BD-32.png',
      },
      {
        name: 'Bulgaria',
        code: 'BG',
        callingCode: '+359',
        flag_32: 'BG-32.png',
      },
      {
        name: 'Bahrain',
        code: 'BH',
        callingCode: '+973',
        flag_32: 'BH-32.png',
      },
      {
        name: 'Bahamas',
        code: 'BS',
        callingCode: '+1242',
        flag_32: 'BS-32.png',
      },
      {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
        callingCode: '+387',
        flag_32: 'BA-32.png',
      },
      {
        name: 'Saint BarthÃ©lemy',
        code: 'BL',
        callingCode: '+590',
      },
      {
        name: 'Belarus',
        code: 'BY',
        callingCode: '+375',
        flag_32: 'BY-32.png',
      },
      {
        name: 'Belize',
        code: 'BZ',
        callingCode: '+501',
        flag_32: 'BZ-32.png',
      },
      {
        name: 'Bermuda',
        code: 'BM',
        callingCode: '+1441',
        flag_32: 'BM-32.png',
      },
      {
        name: 'Bolivia',
        code: 'BO',
        callingCode: '+591',
        flag_32: 'BO-32.png',
      },
      {
        name: 'Brazil',
        code: 'BR',
        callingCode: '+55',
        flag_32: 'BR-32.png',
      },
      {
        name: 'Barbados',
        code: 'BB',
        callingCode: '+1246',
        flag_128: 'BB-128.png',
      },
      {
        name: 'Brunei',
        code: 'BN',
        callingCode: '+673',
        flag_32: 'BN-32.png',
      },
      {
        name: 'Bhutan',
        code: 'BT',
        callingCode: '+975',
        flag_32: 'BT-32.png',
      },
      {
        name: 'Botswana',
        code: 'BW',
        callingCode: '+267',
        flag_32: 'BW-32.png',
      },
      {
        name: 'Central African Republic',
        code: 'CF',
        callingCode: '+236',
        flag_32: 'CF-32.png',
      },
      {
        name: 'Canada',
        code: 'CA',
        callingCode: '+1',
        flag_32: 'CA-32.png',
      },
      {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        callingCode: '+61',
        flag_32: 'CC-32.png',
      },
      {
        name: 'Switzerland',
        code: 'CH',
        callingCode: '+41',
      },
      {
        name: 'Chile',
        code: 'CL',
        callingCode: '+56',
        flag_32: 'CL-32.png',
      },
      {
        name: 'China',
        code: 'CN',
        callingCode: '+86',
        flag_32: 'CN-32.png',
      },
      {
        name: 'Ivory Coast',
        code: 'CI',
        callingCode: '+225',
        flag_32: 'CI-32.png',
      },
      {
        name: 'Cameroon',
        code: 'CM',
        callingCode: '+237',
        flag_32: 'CM-32.png',
      },
      {
        name: 'DR Congo',
        code: 'CD',
        callingCode: '+243',
        flag_32: 'CD-32.png',
      },
      {
        name: 'Republic of the Congo',
        code: 'CG',
        callingCode: '+242',
        flag_32: 'CG-32.png',
      },
      {
        name: 'Cook Islands',
        code: 'CK',
        callingCode: '+682',
        flag_32: 'CK-32.png',
      },
      {
        name: 'Colombia',
        code: 'CO',
        callingCode: '+57',
        flag_32: 'CO-32.png',
      },
      {
        name: 'Comoros',
        code: 'KM',
        callingCode: '+269',
        flag_32: 'KM-32.png',
      },
      {
        name: 'Cape Verde',
        code: 'CV',
        callingCode: '+238',
        flag_32: 'CV-32.png',
      },
      {
        name: 'Costa Rica',
        code: 'CR',
        callingCode: '+506',
        flag_32: 'CR-32.png',
      },
      {
        name: 'Cuba',
        code: 'CU',
        callingCode: '+53',
        flag_32: 'CU-32.png',
      },
      {
        name: 'CuraÃ§ao',
        code: 'CW',
        callingCode: '+5999',
        flag_32: 'CW-32.png',
      },
      {
        name: 'Christmas Island',
        code: 'CX',
        callingCode: '+61',
        flag_32: 'CX-32.png',
      },
      {
        name: 'Cayman Islands',
        code: 'KY',
        callingCode: '+1345',
        flag_32: 'KY-32.png',
      },
      {
        name: 'Cyprus',
        code: 'CY',
        callingCode: '+357',
        flag_32: 'CY-32.png',
      },
      {
        name: 'Czechia',
        code: 'CZ',
        callingCode: '+420',
        flag_32: 'CZ-32.png',
      },
      {
        name: 'Germany',
        code: 'DE',
        callingCode: '+49',
        flag_32: 'DE-32.png',
      },
      {
        name: 'Djibouti',
        code: 'DJ',
        callingCode: '+253',
        flag_32: 'DJ-32.png',
      },
      {
        name: 'Dominica',
        code: 'DM',
        callingCode: '+1767',
        flag_32: 'DM-32.png',
      },
      {
        name: 'Denmark',
        code: 'DK',
        callingCode: '+45',
        flag_32: 'DK-32.png',
      },
      {
        name: 'Dominican Republic',
        code: 'DO',
        callingCode: '+1809',
        flag_32: 'DO-32.png',
      },
      {
        name: 'Algeria',
        code: 'DZ',
        callingCode: '+213',
        flag_32: 'DZ-32.png',
      },
      {
        name: 'Ecuador',
        code: 'EC',
        callingCode: '+593',
        flag_32: 'EC-32.png',
      },
      {
        name: 'Egypt',
        code: 'EG',
        callingCode: '+20',
        flag_32: 'EG-32.png',
      },
      {
        name: 'Eritrea',
        code: 'ER',
        callingCode: '+291',
        flag_32: 'ER-32.png',
      },
      {
        name: 'Western Sahara',
        code: 'EH',
        callingCode: '+212',
      },
      {
        name: 'Spain',
        code: 'ES',
        callingCode: '+34',
      },
      {
        name: 'Estonia',
        code: 'EE',
        callingCode: '+372',
        flag_32: 'EE-32.png',
      },
      {
        name: 'Ethiopia',
        code: 'ET',
        callingCode: '+251',
        flag_32: 'ET-32.png',
      },
      {
        name: 'Finland',
        code: 'FI',
        callingCode: '+358',
        flag_32: 'FI-32.png',
      },
      {
        name: 'Fiji',
        code: 'FJ',
        callingCode: '+679',
      },
      {
        name: 'Falkland Islands',
        code: 'FK',
        callingCode: '+500',
        flag_32: 'FK-32.png',
      },
      {
        name: 'France',
        code: 'FR',
        callingCode: '+33',
        flag_32: 'FR-32.png',
      },
      {
        name: 'Faroe Islands',
        code: 'FO',
        callingCode: '+298',
        flag_32: 'FO-32.png',
      },
      {
        name: 'Micronesia',
        code: 'FM',
        callingCode: '+691',
      },
      {
        name: 'Gabon',
        code: 'GA',
        callingCode: '+241',
        flag_32: 'GA-32.png',
      },
      {
        name: 'United Kingdom',
        code: 'GB',
        callingCode: '+44',
      },
      {
        name: 'Georgia',
        code: 'GE',
        callingCode: '+995',
        flag_32: 'GE-32.png',
      },
      {
        name: 'Guernsey',
        code: 'GG',
        callingCode: '+44',
        flag_32: 'GG-32.png',
      },
      {
        name: 'Ghana',
        code: 'GH',
        callingCode: '+233',
        flag_32: 'GH-32.png',
      },
      {
        name: 'Gibraltar',
        code: 'GI',
        callingCode: '+350',
        flag_32: 'GI-32.png',
      },
      {
        name: 'Guinea',
        code: 'GN',
        callingCode: '+224',
        flag_32: 'GN-32.png',
      },
      {
        name: 'Guadeloupe',
        code: 'GP',
        callingCode: '+590',
        flag_32: 'GP-32.png',
      },
      {
        name: 'Gambia',
        code: 'GM',
        callingCode: '+220',
      },
      {
        name: 'Guinea-Bissau',
        code: 'GW',
        callingCode: '+245',
        flag_32: 'GW-32.png',
      },
      {
        name: 'Equatorial Guinea',
        code: 'GQ',
        callingCode: '+240',
        flag_32: 'GQ-32.png',
      },
      {
        name: 'Greece',
        code: 'GR',
        callingCode: '+30',
        flag_32: 'GR-32.png',
      },
      {
        name: 'Grenada',
        code: 'GD',
        callingCode: '+1473',
        flag_32: 'GD-32.png',
      },
      {
        name: 'Greenland',
        code: 'GL',
        callingCode: '+299',
        flag_32: 'GL-32.png',
      },
      {
        name: 'Guatemala',
        code: 'GT',
        callingCode: '+502',
        flag_32: 'GT-32.png',
      },
      {
        name: 'French Guiana',
        code: 'GF',
        callingCode: '+594',
        flag_32: 'GF-32.png',
      },
      {
        name: 'Guam',
        code: 'GU',
        callingCode: '+1671',
        flag_32: 'GU-32.png',
      },
      {
        name: 'Guyana',
        code: 'GY',
        callingCode: '+592',
        flag_32: 'GY-32.png',
      },
      {
        name: 'Hong Kong',
        code: 'HK',
        callingCode: '+852',
        flag_32: 'HK-32.png',
      },
      {
        name: 'Honduras',
        code: 'HN',
        callingCode: '+504',
        flag_32: 'HN-32.png',
      },
      {
        name: 'Croatia',
        code: 'HR',
        callingCode: '+385',
        flag_32: 'HR-32.png',
      },
      {
        name: 'Haiti',
        code: 'HT',
        callingCode: '+509',
        flag_32: 'HT-32.png',
      },
      {
        name: 'Hungary',
        code: 'HU',
        callingCode: '+36',
        flag_32: 'HU-32.png',
      },
      {
        name: 'Indonesia',
        code: 'ID',
        callingCode: '+62',
        flag_32: 'ID-32.png',
      },
      {
        name: 'Isle of Man',
        code: 'IM',
        callingCode: '+44',
        flag_32: 'IM-32.png',
      },
      {
        name: 'India',
        code: 'IN',
        callingCode: '+91',
        flag_32: 'IN-32.png',
      },
      {
        name: 'British Indian Ocean Territory',
        code: 'IO',
        callingCode: '+246',
        flag_32: 'IO-32.png',
      },
      {
        name: 'Ireland',
        code: 'IE',
        callingCode: '+353',
        flag_32: 'IE-32.png',
      },
      {
        name: 'Iran',
        code: 'IR',
        callingCode: '+98',
        flag_32: 'IR-32.png',
      },
      {
        name: 'Iraq',
        code: 'IQ',
        callingCode: '+964',
        flag_32: 'IQ-32.png',
      },
      {
        name: 'Iceland',
        code: 'IS',
        callingCode: '+354',
        flag_32: 'IS-32.png',
      },
      {
        name: 'Israel',
        code: 'IL',
        callingCode: '+972',
        flag_32: 'IL-32.png',
      },
      {
        name: 'Italy',
        code: 'IT',
        callingCode: '+39',
        flag_32: 'IT-32.png',
      },
      {
        name: 'Jamaica',
        code: 'JM',
        callingCode: '+1876',
        flag_32: 'JM-32.png',
      },
      {
        name: 'Jersey',
        code: 'JE',
        callingCode: '+44',
        flag_32: 'JE-32.png',
      },
      {
        name: 'Jordan',
        code: 'JO',
        callingCode: '+962',
        flag_32: 'JO-32.png',
      },
      {
        name: 'Japan',
        code: 'JP',
        callingCode: '+81',
        flag_32: 'JP-32.png',
      },
      {
        name: 'Kazakhstan',
        code: 'KZ',
        callingCode: '+76',
        flag_32: 'KZ-32.png',
      },
      {
        name: 'Kenya',
        code: 'KE',
        callingCode: '+254',
        flag_32: 'KE-32.png',
      },
      {
        name: 'Kyrgyzstan',
        code: 'KG',
        callingCode: '+996',
        flag_32: 'KG-32.png',
      },
      {
        name: 'Cambodia',
        code: 'KH',
        callingCode: '+855',
        flag_32: 'KH-32.png',
      },
      {
        name: 'Kiribati',
        code: 'KI',
        callingCode: '+686',
        flag_128: 'KI-128.png',
      },
      {
        name: 'Saint Kitts and Nevis',
        code: 'KN',
        callingCode: '+1869',
      },
      {
        name: 'South Korea',
        code: 'KR',
        callingCode: '+82',
      },
      {
        name: 'Kosovo',
        code: 'XK',
        callingCode: '+383',
      },
      {
        name: 'Kuwait',
        code: 'KW',
        callingCode: '+965',
        flag_32: 'KW-32.png',
      },
      {
        name: 'Laos',
        code: 'LA',
        callingCode: '+856',
        flag_32: 'LA-32.png',
      },
      {
        name: 'Lebanon',
        code: 'LB',
        callingCode: '+961',
      },
      {
        name: 'Liberia',
        code: 'LR',
        callingCode: '+231',
      },
      {
        name: 'Libya',
        code: 'LY',
        callingCode: '+218',
      },
      {
        name: 'Saint Lucia',
        code: 'LC',
        callingCode: '+1758',
      },
      {
        name: 'Liechtenstein',
        code: 'LI',
        callingCode: '+423',
      },
      {
        name: 'Sri Lanka',
        code: 'LK',
        callingCode: '+94',
      },
      {
        name: 'Lesotho',
        code: 'LS',
        callingCode: '+266',
      },
      {
        name: 'Lithuania',
        code: 'LT',
        callingCode: '+370',
      },
      {
        name: 'Luxembourg',
        code: 'LU',
        callingCode: '+352',
      },
      {
        name: 'Latvia',
        code: 'LV',
        callingCode: '+371',
        flag_32: 'LV-32.png',
      },
      {
        name: 'Macau',
        code: 'MO',
        callingCode: '+853',
      },
      {
        name: 'Saint Martin',
        code: 'MF',
        callingCode: '+590',
      },
      {
        name: 'Morocco',
        code: 'MA',
        callingCode: '+212',
      },
      {
        name: 'Monaco',
        code: 'MC',
        callingCode: '+377',
      },
      {
        name: 'Moldova',
        code: 'MD',
        callingCode: '+373',
      },
      {
        name: 'Madagascar',
        code: 'MG',
        callingCode: '+261',
      },
      {
        name: 'Maldives',
        code: 'MV',
        callingCode: '+960',
      },
      {
        name: 'Mexico',
        code: 'MX',
        callingCode: '+52',
      },
      {
        name: 'Marshall Islands',
        code: 'MH',
        callingCode: '+692',
      },
      {
        name: 'Macedonia',
        code: 'MK',
        callingCode: '+389',
      },
      {
        name: 'Mali',
        code: 'ML',
        callingCode: '+223',
      },
      {
        name: 'Malta',
        code: 'MT',
        callingCode: '+356',
      },
      {
        name: 'Myanmar',
        code: 'MM',
        callingCode: '+95',
      },
      {
        name: 'Montenegro',
        code: 'ME',
        callingCode: '+382',
      },
      {
        name: 'Mongolia',
        code: 'MN',
        callingCode: '+976',
      },
      {
        name: 'Northern Mariana Islands',
        code: 'MP',
        callingCode: '+1670',
      },
      {
        name: 'Mozambique',
        code: 'MZ',
        callingCode: '+258',
      },
      {
        name: 'Mauritania',
        code: 'MR',
        callingCode: '+222',
      },
      {
        name: 'Montserrat',
        code: 'MS',
        callingCode: '+1664',
      },
      {
        name: 'Martinique',
        code: 'MQ',
        callingCode: '+596',
      },
      {
        name: 'Mauritius',
        code: 'MU',
        callingCode: '+230',
      },
      {
        name: 'Malawi',
        code: 'MW',
        callingCode: '+265',
      },
      {
        name: 'Malaysia',
        code: 'MY',
        callingCode: '+60',
      },
      {
        name: 'Mayotte',
        code: 'YT',
        callingCode: '+262',
      },
      {
        name: 'Namibia',
        code: 'NA',
        callingCode: '+264',
      },
      {
        name: 'New Caledonia',
        code: 'NC',
        callingCode: '+687',
      },
      {
        name: 'Niger',
        code: 'NE',
        callingCode: '+227',
      },
      {
        name: 'Norfolk Island',
        code: 'NF',
        callingCode: '+672',
      },
      {
        name: 'Nigeria',
        code: 'NG',
        callingCode: '+234',
      },
      {
        name: 'Nicaragua',
        code: 'NI',
        callingCode: '+505',
      },
      {
        name: 'Niue',
        code: 'NU',
        callingCode: '+683',
      },
      {
        name: 'Netherlands',
        code: 'NL',
        callingCode: '+31',
      },
      {
        name: 'Norway',
        code: 'NO',
        callingCode: '+47',
      },
      {
        name: 'Nepal',
        code: 'NP',
        callingCode: '+977',
      },
      {
        name: 'Nauru',
        code: 'NR',
        callingCode: '+674',
      },
      {
        name: 'New Zealand',
        code: 'NZ',
        callingCode: '+64',
      },
      {
        name: 'Oman',
        code: 'OM',
        callingCode: '+968',
      },
      {
        name: 'Pakistan',
        code: 'PK',
        callingCode: '+92',
      },
      {
        name: 'Panama',
        code: 'PA',
        callingCode: '+507',
      },
      {
        name: 'Pitcairn Islands',
        code: 'PN',
        callingCode: '+64',
      },
      {
        name: 'Peru',
        code: 'PE',
        callingCode: '+51',
      },
      {
        name: 'Philippines',
        code: 'PH',
        callingCode: '+63',
      },
      {
        name: 'Palau',
        code: 'PW',
        callingCode: '+680',
      },
      {
        name: 'Papua New Guinea',
        code: 'PG',
        callingCode: '+675',
      },
      {
        name: 'Poland',
        code: 'PL',
        callingCode: '+48',
      },
      {
        name: 'Puerto Rico',
        code: 'PR',
        callingCode: '+1787',
      },
      {
        name: 'Puerto Rico',
        code: 'PR',
        callingCode: '+1939',
      },
      {
        name: 'North Korea',
        code: 'KP',
        callingCode: '+850',
      },
      {
        name: 'Portugal',
        code: 'PT',
        callingCode: '+351',
      },
      {
        name: 'Paraguay',
        code: 'PY',
        callingCode: '+595',
      },
      {
        name: 'Palestine',
        code: 'PS',
        callingCode: '+970',
      },
      {
        name: 'French Polynesia',
        code: 'PF',
        callingCode: '+689',
        flag_32: 'PF-32.png',
      },
      {
        name: 'Qatar',
        code: 'QA',
        callingCode: '+974',
      },
      {
        name: 'RÃ©union',
        code: 'RE',
        callingCode: '+262',
      },
      {
        name: 'Romania',
        code: 'RO',
        callingCode: '+40',
      },
      {
        name: 'Russia',
        code: 'RU',
        callingCode: '+7',
      },
      {
        name: 'Rwanda',
        code: 'RW',
        callingCode: '+250',
      },
      {
        name: 'Saudi Arabia',
        code: 'SA',
        callingCode: '+966',
      },
      {
        name: 'Sudan',
        code: 'SD',
        callingCode: '+249',
      },
      {
        name: 'Senegal',
        code: 'SN',
        callingCode: '+221',
      },
      {
        name: 'Singapore',
        code: 'SG',
        callingCode: '+65',
      },
      {
        name: 'South Georgia',
        code: 'GS',
        callingCode: '+500',
      },
      {
        name: 'Svalbard and Jan Mayen',
        code: 'SJ',
        callingCode: '+4779',
      },
      {
        name: 'Solomon Islands',
        code: 'SB',
        callingCode: '+677',
      },
      {
        name: 'Sierra Leone',
        code: 'SL',
        callingCode: '+232',
      },
      {
        name: 'El Salvador',
        code: 'SV',
        callingCode: '+503',
        flag_32: 'SV-32.png',
      },
      {
        name: 'San Marino',
        code: 'SM',
        callingCode: '+378',
      },
      {
        name: 'Somalia',
        code: 'SO',
        callingCode: '+252',
      },
      {
        name: 'Saint Pierre and Miquelon',
        code: 'PM',
        callingCode: '+508',
      },
      {
        name: 'Serbia',
        code: 'RS',
        callingCode: '+381',
      },
      {
        name: 'South Sudan',
        code: 'SS',
        callingCode: '+211',
      },
      {
        name: 'SÃ£o TomÃ© and PrÃ­ncipe',
        code: 'ST',
        callingCode: '+239',
      },
      {
        name: 'Suriname',
        code: 'SR',
        callingCode: '+597',
      },
      {
        name: 'Slovakia',
        code: 'SK',
        callingCode: '+421',
      },
      {
        name: 'Slovenia',
        code: 'SI',
        callingCode: '+386',
      },
      {
        name: 'Sweden',
        code: 'SE',
        callingCode: '+46',
      },
      {
        name: 'Swaziland',
        code: 'SZ',
        callingCode: '+268',
      },
      {
        name: 'Sint Maarten',
        code: 'SX',
        callingCode: '+1721',
      },
      {
        name: 'Seychelles',
        code: 'SC',
        callingCode: '+248',
      },
      {
        name: 'Syria',
        code: 'SY',
        callingCode: '+963',
      },
      {
        name: 'Turks and Caicos Islands',
        code: 'TC',
        callingCode: '+1649',
      },
      {
        name: 'Chad',
        code: 'TD',
        callingCode: '+235',
        flag_32: 'TD-32.png',
      },
      {
        name: 'Togo',
        code: 'TG',
        callingCode: '+228',
      },
      {
        name: 'Thailand',
        code: 'TH',
        callingCode: '+66',
      },
      {
        name: 'Tajikistan',
        code: 'TJ',
        callingCode: '+992',
      },
      {
        name: 'Tokelau',
        code: 'TK',
        callingCode: '+690',
      },
      {
        name: 'Turkmenistan',
        code: 'TM',
        callingCode: '+993',
      },
      {
        name: 'Timor-Leste',
        code: 'TL',
        callingCode: '+670',
      },
      {
        name: 'Tonga',
        code: 'TO',
        callingCode: '+676',
      },
      {
        name: 'Trinidad and Tobago',
        code: 'TT',
        callingCode: '+1868',
      },
      {
        name: 'Tunisia',
        code: 'TN',
        callingCode: '+216',
      },
      {
        name: 'Turkey',
        code: 'TR',
        callingCode: '+90',
      },
      {
        name: 'Tuvalu',
        code: 'TV',
        callingCode: '+688',
      },
      {
        name: 'Taiwan',
        code: 'TW',
        callingCode: '+886',
      },
      {
        name: 'Tanzania',
        code: 'TZ',
        callingCode: '+255',
      },
      {
        name: 'Uganda',
        code: 'UG',
        callingCode: '+256',
      },
      {
        name: 'Ukraine',
        code: 'UA',
        callingCode: '+380',
      },
      {
        name: 'Uruguay',
        code: 'UY',
        callingCode: '+598',
      },
      {
        name: 'United States',
        code: 'US',
        callingCode: '+1',
      },
      {
        name: 'Uzbekistan',
        code: 'UZ',
        callingCode: '+998',
      },
      {
        name: 'Vatican City',
        code: 'VA',
        callingCode: '+3906698',
      },
      {
        name: 'Vatican City',
        code: 'VA',
        callingCode: '+379',
      },
      {
        name: 'Saint Vincent and the Grenadines',
        code: 'VC',
        callingCode: '+1784',
      },
      {
        name: 'Venezuela',
        code: 'VE',
        callingCode: '+58',
      },
      {
        name: 'British Virgin Islands',
        code: 'VG',
        callingCode: '+1284',
      },
      {
        name: 'United States Virgin Islands',
        code: 'VI',
        callingCode: '+1340',
      },
      {
        name: 'Vietnam',
        code: 'VN',
        callingCode: '+84',
      },
      {
        name: 'Vanuatu',
        code: 'VU',
        callingCode: '+678',
      },
      {
        name: 'Wallis and Futuna',
        code: 'WF',
        callingCode: '+681',
      },
      {
        name: 'Samoa',
        code: 'WS',
        callingCode: '+685',
      },
      {
        name: 'Yemen',
        code: 'YE',
        callingCode: '+967',
      },
      {
        name: 'South Africa',
        code: 'ZA',
        callingCode: '+27',
      },
      {
        name: 'Zambia',
        code: 'ZM',
        callingCode: '+260',
      },
      {
        name: 'Zimbabwe',
        code: 'ZW',
        callingCode: '+263',
      },
    ];
    return phoneList;
  },

  getCurrency() {
    var currencyList = [
      {
        symbol: '$',
        name: 'US Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'USD',
        name_plural: 'US dollars',
      },
      {
        symbol: 'CA$',
        name: 'Canadian Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'CAD',
        name_plural: 'Canadian dollars',
      },
      {
        symbol: '€',
        name: 'Euro',
        symbol_native: '€',
        decimal_digits: 2,
        rounding: 0,
        code: 'EUR',
        name_plural: 'euros',
      },
      {
        symbol: 'AED',
        name: 'United Arab Emirates Dirham',
        symbol_native: 'د.إ.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'AED',
        name_plural: 'UAE dirhams',
      },
      {
        symbol: 'Af',
        name: 'Afghan Afghani',
        symbol_native: '؋',
        decimal_digits: 0,
        rounding: 0,
        code: 'AFN',
        name_plural: 'Afghan Afghanis',
      },
      {
        symbol: 'ALL',
        name: 'Albanian Lek',
        symbol_native: 'Lek',
        decimal_digits: 0,
        rounding: 0,
        code: 'ALL',
        name_plural: 'Albanian lekë',
      },
      {
        symbol: 'AMD',
        name: 'Armenian Dram',
        symbol_native: 'դր.',
        decimal_digits: 0,
        rounding: 0,
        code: 'AMD',
        name_plural: 'Armenian drams',
      },
      {
        symbol: 'AR$',
        name: 'Argentine Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'ARS',
        name_plural: 'Argentine pesos',
      },
      {
        symbol: 'AU$',
        name: 'Australian Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'AUD',
        name_plural: 'Australian dollars',
      },
      {
        symbol: 'man.',
        name: 'Azerbaijani Manat',
        symbol_native: 'ман.',
        decimal_digits: 2,
        rounding: 0,
        code: 'AZN',
        name_plural: 'Azerbaijani manats',
      },
      {
        symbol: 'KM',
        name: 'Bosnia-Herzegovina Convertible Mark',
        symbol_native: 'KM',
        decimal_digits: 2,
        rounding: 0,
        code: 'BAM',
        name_plural: 'Bosnia-Herzegovina convertible marks',
      },
      {
        symbol: 'Tk',
        name: 'Bangladeshi Taka',
        symbol_native: '৳',
        decimal_digits: 2,
        rounding: 0,
        code: 'BDT',
        name_plural: 'Bangladeshi takas',
      },
      {
        symbol: 'BGN',
        name: 'Bulgarian Lev',
        symbol_native: 'лв.',
        decimal_digits: 2,
        rounding: 0,
        code: 'BGN',
        name_plural: 'Bulgarian leva',
      },
      {
        symbol: 'BD',
        name: 'Bahraini Dinar',
        symbol_native: 'د.ب.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'BHD',
        name_plural: 'Bahraini dinars',
      },
      {
        symbol: 'FBu',
        name: 'Burundian Franc',
        symbol_native: 'FBu',
        decimal_digits: 0,
        rounding: 0,
        code: 'BIF',
        name_plural: 'Burundian francs',
      },
      {
        symbol: 'BN$',
        name: 'Brunei Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BND',
        name_plural: 'Brunei dollars',
      },
      {
        symbol: 'Bs',
        name: 'Bolivian Boliviano',
        symbol_native: 'Bs',
        decimal_digits: 2,
        rounding: 0,
        code: 'BOB',
        name_plural: 'Bolivian bolivianos',
      },
      {
        symbol: 'R$',
        name: 'Brazilian Real',
        symbol_native: 'R$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BRL',
        name_plural: 'Brazilian reals',
      },
      {
        symbol: 'BWP',
        name: 'Botswanan Pula',
        symbol_native: 'P',
        decimal_digits: 2,
        rounding: 0,
        code: 'BWP',
        name_plural: 'Botswanan pulas',
      },
      {
        symbol: 'BYR',
        name: 'Belarusian Ruble',
        symbol_native: 'BYR',
        decimal_digits: 0,
        rounding: 0,
        code: 'BYR',
        name_plural: 'Belarusian rubles',
      },
      {
        symbol: 'BZ$',
        name: 'Belize Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'BZD',
        name_plural: 'Belize dollars',
      },
      {
        symbol: 'CDF',
        name: 'Congolese Franc',
        symbol_native: 'FrCD',
        decimal_digits: 2,
        rounding: 0,
        code: 'CDF',
        name_plural: 'Congolese francs',
      },
      {
        symbol: 'CHF',
        name: 'Swiss Franc',
        symbol_native: 'CHF',
        decimal_digits: 2,
        rounding: 0.05,
        code: 'CHF',
        name_plural: 'Swiss francs',
      },
      {
        symbol: 'CL$',
        name: 'Chilean Peso',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        code: 'CLP',
        name_plural: 'Chilean pesos',
      },
      {
        symbol: 'CN¥',
        name: 'Chinese Yuan',
        symbol_native: 'CN¥',
        decimal_digits: 2,
        rounding: 0,
        code: 'CNY',
        name_plural: 'Chinese yuan',
      },
      {
        symbol: 'CO$',
        name: 'Colombian Peso',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        code: 'COP',
        name_plural: 'Colombian pesos',
      },
      {
        symbol: '₡',
        name: 'Costa Rican Colón',
        symbol_native: '₡',
        decimal_digits: 0,
        rounding: 0,
        code: 'CRC',
        name_plural: 'Costa Rican colóns',
      },
      {
        symbol: 'CV$',
        name: 'Cape Verdean Escudo',
        symbol_native: 'CV$',
        decimal_digits: 2,
        rounding: 0,
        code: 'CVE',
        name_plural: 'Cape Verdean escudos',
      },
      {
        symbol: 'Kč',
        name: 'Czech Republic Koruna',
        symbol_native: 'Kč',
        decimal_digits: 2,
        rounding: 0,
        code: 'CZK',
        name_plural: 'Czech Republic korunas',
      },
      {
        symbol: 'Fdj',
        name: 'Djiboutian Franc',
        symbol_native: 'Fdj',
        decimal_digits: 0,
        rounding: 0,
        code: 'DJF',
        name_plural: 'Djiboutian francs',
      },
      {
        symbol: 'Dkr',
        name: 'Danish Krone',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'DKK',
        name_plural: 'Danish kroner',
      },
      {
        symbol: 'RD$',
        name: 'Dominican Peso',
        symbol_native: 'RD$',
        decimal_digits: 2,
        rounding: 0,
        code: 'DOP',
        name_plural: 'Dominican pesos',
      },
      {
        symbol: 'DA',
        name: 'Algerian Dinar',
        symbol_native: 'د.ج.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'DZD',
        name_plural: 'Algerian dinars',
      },
      {
        symbol: 'Ekr',
        name: 'Estonian Kroon',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'EEK',
        name_plural: 'Estonian kroons',
      },
      {
        symbol: 'EGP',
        name: 'Egyptian Pound',
        symbol_native: 'ج.م.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'EGP',
        name_plural: 'Egyptian pounds',
      },
      {
        symbol: 'Nfk',
        name: 'Eritrean Nakfa',
        symbol_native: 'Nfk',
        decimal_digits: 2,
        rounding: 0,
        code: 'ERN',
        name_plural: 'Eritrean nakfas',
      },
      {
        symbol: 'Br',
        name: 'Ethiopian Birr',
        symbol_native: 'Br',
        decimal_digits: 2,
        rounding: 0,
        code: 'ETB',
        name_plural: 'Ethiopian birrs',
      },
      {
        symbol: '£',
        name: 'British Pound Sterling',
        symbol_native: '£',
        decimal_digits: 2,
        rounding: 0,
        code: 'GBP',
        name_plural: 'British pounds sterling',
      },
      {
        symbol: 'GEL',
        name: 'Georgian Lari',
        symbol_native: 'GEL',
        decimal_digits: 2,
        rounding: 0,
        code: 'GEL',
        name_plural: 'Georgian laris',
      },
      {
        symbol: 'GH₵',
        name: 'Ghanaian Cedi',
        symbol_native: 'GH₵',
        decimal_digits: 2,
        rounding: 0,
        code: 'GHS',
        name_plural: 'Ghanaian cedis',
      },
      {
        symbol: 'FG',
        name: 'Guinean Franc',
        symbol_native: 'FG',
        decimal_digits: 0,
        rounding: 0,
        code: 'GNF',
        name_plural: 'Guinean francs',
      },
      {
        symbol: 'GTQ',
        name: 'Guatemalan Quetzal',
        symbol_native: 'Q',
        decimal_digits: 2,
        rounding: 0,
        code: 'GTQ',
        name_plural: 'Guatemalan quetzals',
      },
      {
        symbol: 'HK$',
        name: 'Hong Kong Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'HKD',
        name_plural: 'Hong Kong dollars',
      },
      {
        symbol: 'HNL',
        name: 'Honduran Lempira',
        symbol_native: 'L',
        decimal_digits: 2,
        rounding: 0,
        code: 'HNL',
        name_plural: 'Honduran lempiras',
      },
      {
        symbol: 'kn',
        name: 'Croatian Kuna',
        symbol_native: 'kn',
        decimal_digits: 2,
        rounding: 0,
        code: 'HRK',
        name_plural: 'Croatian kunas',
      },
      {
        symbol: 'Ft',
        name: 'Hungarian Forint',
        symbol_native: 'Ft',
        decimal_digits: 0,
        rounding: 0,
        code: 'HUF',
        name_plural: 'Hungarian forints',
      },
      {
        symbol: 'Rp',
        name: 'Indonesian Rupiah',
        symbol_native: 'Rp',
        decimal_digits: 0,
        rounding: 0,
        code: 'IDR',
        name_plural: 'Indonesian rupiahs',
      },
      {
        symbol: '₪',
        name: 'Israeli New Sheqel',
        symbol_native: '₪',
        decimal_digits: 2,
        rounding: 0,
        code: 'ILS',
        name_plural: 'Israeli new sheqels',
      },
      {
        symbol: 'Rs',
        name: 'Indian Rupee',
        symbol_native: 'টকা',
        decimal_digits: 2,
        rounding: 0,
        code: 'INR',
        name_plural: 'Indian rupees',
      },
      {
        symbol: 'IQD',
        name: 'Iraqi Dinar',
        symbol_native: 'د.ع.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'IQD',
        name_plural: 'Iraqi dinars',
      },
      {
        symbol: 'IRR',
        name: 'Iranian Rial',
        symbol_native: '﷼',
        decimal_digits: 0,
        rounding: 0,
        code: 'IRR',
        name_plural: 'Iranian rials',
      },
      {
        symbol: 'Ikr',
        name: 'Icelandic Króna',
        symbol_native: 'kr',
        decimal_digits: 0,
        rounding: 0,
        code: 'ISK',
        name_plural: 'Icelandic krónur',
      },
      {
        symbol: 'J$',
        name: 'Jamaican Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'JMD',
        name_plural: 'Jamaican dollars',
      },
      {
        symbol: 'JD',
        name: 'Jordanian Dinar',
        symbol_native: 'د.أ.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'JOD',
        name_plural: 'Jordanian dinars',
      },
      {
        symbol: '¥',
        name: 'Japanese Yen',
        symbol_native: '￥',
        decimal_digits: 0,
        rounding: 0,
        code: 'JPY',
        name_plural: 'Japanese yen',
      },
      {
        symbol: 'Ksh',
        name: 'Kenyan Shilling',
        symbol_native: 'Ksh',
        decimal_digits: 2,
        rounding: 0,
        code: 'KES',
        name_plural: 'Kenyan shillings',
      },
      {
        symbol: 'KHR',
        name: 'Cambodian Riel',
        symbol_native: '៛',
        decimal_digits: 2,
        rounding: 0,
        code: 'KHR',
        name_plural: 'Cambodian riels',
      },
      {
        symbol: 'CF',
        name: 'Comorian Franc',
        symbol_native: 'FC',
        decimal_digits: 0,
        rounding: 0,
        code: 'KMF',
        name_plural: 'Comorian francs',
      },
      {
        symbol: '₩',
        name: 'South Korean Won',
        symbol_native: '₩',
        decimal_digits: 0,
        rounding: 0,
        code: 'KRW',
        name_plural: 'South Korean won',
      },
      {
        symbol: 'KD',
        name: 'Kuwaiti Dinar',
        symbol_native: 'د.ك.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'KWD',
        name_plural: 'Kuwaiti dinars',
      },
      {
        symbol: 'KZT',
        name: 'Kazakhstani Tenge',
        symbol_native: 'тңг.',
        decimal_digits: 2,
        rounding: 0,
        code: 'KZT',
        name_plural: 'Kazakhstani tenges',
      },
      {
        symbol: 'LB£',
        name: 'Lebanese Pound',
        symbol_native: 'ل.ل.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'LBP',
        name_plural: 'Lebanese pounds',
      },
      {
        symbol: 'SLRs',
        name: 'Sri Lankan Rupee',
        symbol_native: 'SL Re',
        decimal_digits: 2,
        rounding: 0,
        code: 'LKR',
        name_plural: 'Sri Lankan rupees',
      },
      {
        symbol: 'Lt',
        name: 'Lithuanian Litas',
        symbol_native: 'Lt',
        decimal_digits: 2,
        rounding: 0,
        code: 'LTL',
        name_plural: 'Lithuanian litai',
      },
      {
        symbol: 'Ls',
        name: 'Latvian Lats',
        symbol_native: 'Ls',
        decimal_digits: 2,
        rounding: 0,
        code: 'LVL',
        name_plural: 'Latvian lati',
      },
      {
        symbol: 'LD',
        name: 'Libyan Dinar',
        symbol_native: 'د.ل.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'LYD',
        name_plural: 'Libyan dinars',
      },
      {
        symbol: 'MAD',
        name: 'Moroccan Dirham',
        symbol_native: 'د.م.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'MAD',
        name_plural: 'Moroccan dirhams',
      },
      {
        symbol: 'MDL',
        name: 'Moldovan Leu',
        symbol_native: 'MDL',
        decimal_digits: 2,
        rounding: 0,
        code: 'MDL',
        name_plural: 'Moldovan lei',
      },
      {
        symbol: 'MGA',
        name: 'Malagasy Ariary',
        symbol_native: 'MGA',
        decimal_digits: 0,
        rounding: 0,
        code: 'MGA',
        name_plural: 'Malagasy Ariaries',
      },
      {
        symbol: 'MKD',
        name: 'Macedonian Denar',
        symbol_native: 'MKD',
        decimal_digits: 2,
        rounding: 0,
        code: 'MKD',
        name_plural: 'Macedonian denari',
      },
      {
        symbol: 'MMK',
        name: 'Myanma Kyat',
        symbol_native: 'K',
        decimal_digits: 0,
        rounding: 0,
        code: 'MMK',
        name_plural: 'Myanma kyats',
      },
      {
        symbol: 'MOP$',
        name: 'Macanese Pataca',
        symbol_native: 'MOP$',
        decimal_digits: 2,
        rounding: 0,
        code: 'MOP',
        name_plural: 'Macanese patacas',
      },
      {
        symbol: 'MURs',
        name: 'Mauritian Rupee',
        symbol_native: 'MURs',
        decimal_digits: 0,
        rounding: 0,
        code: 'MUR',
        name_plural: 'Mauritian rupees',
      },
      {
        symbol: 'MX$',
        name: 'Mexican Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'MXN',
        name_plural: 'Mexican pesos',
      },
      {
        symbol: 'RM',
        name: 'Malaysian Ringgit',
        symbol_native: 'RM',
        decimal_digits: 2,
        rounding: 0,
        code: 'MYR',
        name_plural: 'Malaysian ringgits',
      },
      {
        symbol: 'MTn',
        name: 'Mozambican Metical',
        symbol_native: 'MTn',
        decimal_digits: 2,
        rounding: 0,
        code: 'MZN',
        name_plural: 'Mozambican meticals',
      },
      {
        symbol: 'N$',
        name: 'Namibian Dollar',
        symbol_native: 'N$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NAD',
        name_plural: 'Namibian dollars',
      },
      {
        symbol: '₦',
        name: 'Nigerian Naira',
        symbol_native: '₦',
        decimal_digits: 2,
        rounding: 0,
        code: 'NGN',
        name_plural: 'Nigerian nairas',
      },
      {
        symbol: 'C$',
        name: 'Nicaraguan Córdoba',
        symbol_native: 'C$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NIO',
        name_plural: 'Nicaraguan córdobas',
      },
      {
        symbol: 'Nkr',
        name: 'Norwegian Krone',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'NOK',
        name_plural: 'Norwegian kroner',
      },
      {
        symbol: 'NPRs',
        name: 'Nepalese Rupee',
        symbol_native: 'नेरू',
        decimal_digits: 2,
        rounding: 0,
        code: 'NPR',
        name_plural: 'Nepalese rupees',
      },
      {
        symbol: 'NZ$',
        name: 'New Zealand Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'NZD',
        name_plural: 'New Zealand dollars',
      },
      {
        symbol: 'OMR',
        name: 'Omani Rial',
        symbol_native: 'ر.ع.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'OMR',
        name_plural: 'Omani rials',
      },
      {
        symbol: 'B/.',
        name: 'Panamanian Balboa',
        symbol_native: 'B/.',
        decimal_digits: 2,
        rounding: 0,
        code: 'PAB',
        name_plural: 'Panamanian balboas',
      },
      {
        symbol: 'S/.',
        name: 'Peruvian Nuevo Sol',
        symbol_native: 'S/.',
        decimal_digits: 2,
        rounding: 0,
        code: 'PEN',
        name_plural: 'Peruvian nuevos soles',
      },
      {
        symbol: '₱',
        name: 'Philippine Peso',
        symbol_native: '₱',
        decimal_digits: 2,
        rounding: 0,
        code: 'PHP',
        name_plural: 'Philippine pesos',
      },
      {
        symbol: 'PKRs',
        name: 'Pakistani Rupee',
        symbol_native: '₨',
        decimal_digits: 0,
        rounding: 0,
        code: 'PKR',
        name_plural: 'Pakistani rupees',
      },
      {
        symbol: 'zł',
        name: 'Polish Zloty',
        symbol_native: 'zł',
        decimal_digits: 2,
        rounding: 0,
        code: 'PLN',
        name_plural: 'Polish zlotys',
      },
      {
        symbol: '₲',
        name: 'Paraguayan Guarani',
        symbol_native: '₲',
        decimal_digits: 0,
        rounding: 0,
        code: 'PYG',
        name_plural: 'Paraguayan guaranis',
      },
      {
        symbol: 'QR',
        name: 'Qatari Rial',
        symbol_native: 'ر.ق.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'QAR',
        name_plural: 'Qatari rials',
      },
      {
        symbol: 'RON',
        name: 'Romanian Leu',
        symbol_native: 'RON',
        decimal_digits: 2,
        rounding: 0,
        code: 'RON',
        name_plural: 'Romanian lei',
      },
      {
        symbol: 'din.',
        name: 'Serbian Dinar',
        symbol_native: 'дин.',
        decimal_digits: 0,
        rounding: 0,
        code: 'RSD',
        name_plural: 'Serbian dinars',
      },
      {
        symbol: 'RUB',
        name: 'Russian Ruble',
        symbol_native: 'руб.',
        decimal_digits: 2,
        rounding: 0,
        code: 'RUB',
        name_plural: 'Russian rubles',
      },
      {
        symbol: 'RWF',
        name: 'Rwandan Franc',
        symbol_native: 'FR',
        decimal_digits: 0,
        rounding: 0,
        code: 'RWF',
        name_plural: 'Rwandan francs',
      },
      {
        symbol: 'SR',
        name: 'Saudi Riyal',
        symbol_native: 'ر.س.‏',
        decimal_digits: 2,
        rounding: 0,
        code: 'SAR',
        name_plural: 'Saudi riyals',
      },
      {
        symbol: 'SDG',
        name: 'Sudanese Pound',
        symbol_native: 'SDG',
        decimal_digits: 2,
        rounding: 0,
        code: 'SDG',
        name_plural: 'Sudanese pounds',
      },
      {
        symbol: 'Skr',
        name: 'Swedish Krona',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        code: 'SEK',
        name_plural: 'Swedish kronor',
      },
      {
        symbol: 'S$',
        name: 'Singapore Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'SGD',
        name_plural: 'Singapore dollars',
      },
      {
        symbol: 'Ssh',
        name: 'Somali Shilling',
        symbol_native: 'Ssh',
        decimal_digits: 0,
        rounding: 0,
        code: 'SOS',
        name_plural: 'Somali shillings',
      },
      {
        symbol: 'SY£',
        name: 'Syrian Pound',
        symbol_native: 'ل.س.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'SYP',
        name_plural: 'Syrian pounds',
      },
      {
        symbol: '฿',
        name: 'Thai Baht',
        symbol_native: '฿',
        decimal_digits: 2,
        rounding: 0,
        code: 'THB',
        name_plural: 'Thai baht',
      },
      {
        symbol: 'DT',
        name: 'Tunisian Dinar',
        symbol_native: 'د.ت.‏',
        decimal_digits: 3,
        rounding: 0,
        code: 'TND',
        name_plural: 'Tunisian dinars',
      },
      {
        symbol: 'T$',
        name: 'Tongan Paʻanga',
        symbol_native: 'T$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TOP',
        name_plural: 'Tongan paʻanga',
      },
      {
        symbol: 'TL',
        name: 'Turkish Lira',
        symbol_native: 'TL',
        decimal_digits: 2,
        rounding: 0,
        code: 'TRY',
        name_plural: 'Turkish Lira',
      },
      {
        symbol: 'TT$',
        name: 'Trinidad and Tobago Dollar',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TTD',
        name_plural: 'Trinidad and Tobago dollars',
      },
      {
        symbol: 'NT$',
        name: 'New Taiwan Dollar',
        symbol_native: 'NT$',
        decimal_digits: 2,
        rounding: 0,
        code: 'TWD',
        name_plural: 'New Taiwan dollars',
      },
      {
        symbol: 'TSh',
        name: 'Tanzanian Shilling',
        symbol_native: 'TSh',
        decimal_digits: 0,
        rounding: 0,
        code: 'TZS',
        name_plural: 'Tanzanian shillings',
      },
      {
        symbol: '₴',
        name: 'Ukrainian Hryvnia',
        symbol_native: '₴',
        decimal_digits: 2,
        rounding: 0,
        code: 'UAH',
        name_plural: 'Ukrainian hryvnias',
      },
      {
        symbol: 'USh',
        name: 'Ugandan Shilling',
        symbol_native: 'USh',
        decimal_digits: 0,
        rounding: 0,
        code: 'UGX',
        name_plural: 'Ugandan shillings',
      },
      {
        symbol: '$U',
        name: 'Uruguayan Peso',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        code: 'UYU',
        name_plural: 'Uruguayan pesos',
      },
      {
        symbol: 'UZS',
        name: 'Uzbekistan Som',
        symbol_native: 'UZS',
        decimal_digits: 0,
        rounding: 0,
        code: 'UZS',
        name_plural: 'Uzbekistan som',
      },
      {
        symbol: 'Bs.F.',
        name: 'Venezuelan Bolívar',
        symbol_native: 'Bs.F.',
        decimal_digits: 2,
        rounding: 0,
        code: 'VEF',
        name_plural: 'Venezuelan bolívars',
      },
      {
        symbol: '₫',
        name: 'Vietnamese Dong',
        symbol_native: '₫',
        decimal_digits: 0,
        rounding: 0,
        code: 'VND',
        name_plural: 'Vietnamese dong',
      },
      {
        symbol: 'FCFA',
        name: 'CFA Franc BEAC',
        symbol_native: 'FCFA',
        decimal_digits: 0,
        rounding: 0,
        code: 'XAF',
        name_plural: 'CFA francs BEAC',
      },
      {
        symbol: 'CFA',
        name: 'CFA Franc BCEAO',
        symbol_native: 'CFA',
        decimal_digits: 0,
        rounding: 0,
        code: 'XOF',
        name_plural: 'CFA francs BCEAO',
      },
      {
        symbol: 'YR',
        name: 'Yemeni Rial',
        symbol_native: 'ر.ي.‏',
        decimal_digits: 0,
        rounding: 0,
        code: 'YER',
        name_plural: 'Yemeni rials',
      },
      {
        symbol: 'R',
        name: 'South African Rand',
        symbol_native: 'R',
        decimal_digits: 2,
        rounding: 0,
        code: 'ZAR',
        name_plural: 'South African rand',
      },
      {
        symbol: 'ZK',
        name: 'Zambian Kwacha',
        symbol_native: 'ZK',
        decimal_digits: 0,
        rounding: 0,
        code: 'ZMK',
        name_plural: 'Zambian kwachas',
      },
    ];
    return currencyList;
  },

  getEditorConfig() {
    // var editorConfig: AngularEditorConfig = {
    //   editable: true,
    //   spellcheck: true,
    //   height: '50',
    //   minHeight: '50',
    //   maxHeight: 'auto',
    //   width: 'auto',
    //   minWidth: '0',
    //   // translate: 'yes',
    //   enableToolbar: true,
    //   showToolbar: true,
    //   placeholder: 'Enter vision statement',
    //   defaultParagraphSeparator: '',
    //   defaultFontName: '',
    //   defaultFontSize: '',
    //   // fonts: [
    //   //   {class: 'arial', name: 'Arial'},
    //   //   {class: 'times-new-roman', name: 'Times New Roman'},
    //   //   {class: 'calibri', name: 'Calibri'},
    //   //   {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    //   // ],
    //   customClasses: [
    //     {
    //       name: 'quote',
    //       class: 'quote',
    //     },
    //     //   {
    //     //     name: 'redText',
    //     //     class: 'redText'
    //     //   },
    //     //   {
    //     //     name: 'titleText',
    //     //     class: 'titleText',
    //     //     tag: 'h1',
    //     //   },
    //   ],
    //   // uploadUrl: 'v1/image',
    //   // upload: (file: File) => { ... }
    //   // uploadWithCredentials: false,
    //   sanitize: true,
    //   toolbarPosition: 'top',
    //   toolbarHiddenButtons: [
    //     [
    //       // 'undo',
    //       // 'redo',
    //       // 'bold',
    //       // 'italic',
    //       'underline',
    //       // 'strikeThrough',
    //       'subscript',
    //       'superscript',
    //       'justifyLeft',
    //       'justifyCenter',
    //       'justifyRight',
    //       'justifyFull',
    //       'indent',
    //       'outdent',
    //       'insertUnorderedList',
    //       'insertOrderedList',
    //       'heading',
    //       'fontName',
    //     ],
    //     [
    //       'fontSize',
    //       'textColor',
    //       'backgroundColor',
    //       // 'customClasses',
    //       // 'link',
    //       'unlink',
    //       'insertImage',
    //       'insertVideo',
    //       'insertHorizontalRule',
    //       'removeFormat',
    //       'toggleEditorMode',
    //     ],
    //   ],
    // };

    // return editorConfig;
  },
};
